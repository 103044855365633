<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Veja quantidade e valor total das saídas de produtos de acordo com os filtros selecionados. Clique sobre um produto para visualizar seus respectivos comprovantes.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
						<div class="col-xl-7 px-0">
							<div class="row">
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-file color-theme font-11 me-1"></i> Categoria</label>
									<v-select name="Desktop" :options="listaCategorias" v-model="pesquisa.categoria" label="nome" :reduce="c => c.nome" placeholder="Todas as categorias"
										@input="buscarSubcategorias(pesquisa.categoria)">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.categoria" @change="buscarSubcategorias(pesquisa.categoria)">
										<option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria</label>
									<v-select name="Desktop" :options="listaSubcategorias" v-model="pesquisa.subcategoria" placeholder="Todas as subcategorias">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.subcategoria">
										<option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-bookmark color-theme font-11 me-1"></i> Marca</label>
									<v-select name="Desktop" :options="listaMarcas" v-model="pesquisa.marca" label="nome" :reduce="m => m.nome" placeholder="Todas as marcas">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Marcas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.marca">
										<option v-for="(option, index) in listaMarcas" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3" v-if="pesquisa.searchOperador">
									<label><i class="far fa-user color-theme font-11 me-1"></i> Operador(a)</label>
									<v-select @search="onSearchOperadores" :options="listaOperadores" v-model="pesquisa.operador" label="nomeCompleto" :reduce="o => o.id" placeholder="Todas os operadores">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Digite a menos 3 caracteres para buscar</small>
										</template>
									</v-select>
								</div>
								<div class="col-6 col-md-3 mb-3" v-else>
									<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
									<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="l => l.id" placeholder="Todas as lojas">
										<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.loja">
										<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-xl-5 px-0">
							<div class="row">
								<div class="col-sm-5 col-md-4 mb-3">
									<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
									<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
								</div>
								<div class="col-9 col-sm-5 col-md-6 mb-3">
									<label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome/Código/Família</label>
									<input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarProdutos" placeholder="Busque por nome do produto, código ou família" />
								</div>
								<div class="col-3 col-sm-2 mb-3 align-self-end">
									<div class="dropdown dropstart text-end">
										<a class="dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">
											<i class='bx bx-dots-horizontal-rounded color-theme font-22'></i>
										</a>
										<ul class="dropdown-menu">
											<li class="dropdown-item pt-2 pb-12"><strong class="weight-500"><i class="far fa-sort-amount-down color-theme font-12 me-1"></i> Ordenação</strong></li>
											<li class="dropdown-item">
												<div class="form-check form-switch mb-0">
													<input class="form-check-input" type="checkbox" id="ascendente" :checked="pesquisa.orderAscendente"
														@click="pesquisa.orderAscendente = !pesquisa.orderAscendente; pesquisa.retorno.resultado = [];">
													<label class="form-check-label m-0 ps-1 pt-1 font-13" for="ascendente">Ascendente</label>
												</div>
											</li>
											<li class="dropdown-item">
												<div class="form-check form-switch mb-0">
													<input class="form-check-input" type="checkbox" id="quantidade" :checked="pesquisa.orderQuantidade"
														@click="pesquisa.orderQuantidade = !pesquisa.orderQuantidade; pesquisa.retorno.resultado = [];">
													<label class="form-check-label m-0 ps-1 pt-1 font-13" for="quantidade">Quantidade</label>
												</div>
											</li>
											<li><hr class="dropdown-divider"></li>
											<li class="dropdown-item pt-2 pb-12"><strong class="weight-500"><i class="far fa-search color-theme font-11 me-1"></i> Pesquisa</strong></li>
											<li class="dropdown-item">
												<div class="form-check form-switch mb-0">
													<input class="form-check-input" type="checkbox" id="operador" :checked="pesquisa.searchOperador"
														@click="pesquisa.searchOperador = !pesquisa.searchOperador; pesquisa.retorno.resultado = []; listaOperadores = [];">
													<label class="form-check-label m-0 ps-1 pt-1 font-13" for="operador">Operador(a)</label>
												</div>
											</li>
										</ul>
									</div>
									<button type="button" class="btn btn-primary w-100" @click="buscarProdutos"><i class="fas fa-search font-13"></i></button>
								</div>
							</div>
						</div>
					</div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 500 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<produto v-for="(produto, index) in pesquisa.retorno.resultado" :key="index" :produto="produto" :index="index" @buscarComprovantes="buscarComprovantes($event, false)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">Comprovantes ({{ modal.nomeProduto }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="modal.resultado.length > 0">
                  <div class="cards-150 cards-sm-200">
                     <comprovante v-for="(cupom, index) in modal.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
                  </div>

                  <div class="w-100 mt-3 mb-2 text-center" v-if="modal.resultado.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
                  <i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum comprovante encontrado</h4>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" v-if="modal.resultado.length > 0" @click="buscarComprovantes(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<modalComprovante :modalAnterior="'#modalComprovantes'" :tipo="comprovante.tipo" :comprovante="comprovante.dados" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import comprovante from '@/components/modelos/Comprovante.vue'
import produto from '@/components/resultados/vendasProdutos/Produto.vue'
import modalComprovante from '@/components/modelos/ModalComprovante.vue'

export default {
	name: 'Saídas_de_produtos',
	data: function () {
      return {
         pesquisa: {
				'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'loja': null, 'data': [new Date(), new Date()], 
				'operador': null, 'orderAscendente': false, 'orderQuantidade': false, 'searchOperador': false, 'isEntrada': false,
				'retorno': {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'loja': null, 'operador': null, 'data': null, 'resultado': []}
			},
			modal: {'idProduto': null, 'nomeProduto': null, 'operador': null, 'loja': null, 'data': [new Date(), new Date()], 'pagina': 0, 'resultado': []},
			comprovante: {'tipo': null, 'dados': null},
			listaSubcategorias: [],
			listaOperadores: [],
			listaExportacao: []
		}
	},
	computed: {
      ... mapState({
			listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
      produto, comprovante, modalComprovante
   },
	methods: {
		imageError : function (e) {
			this.$store.dispatch('imageError', e)
		},
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Vendas de produtos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos':  ['id', 'nome', 'codigos', 'marca', 'familia', 'unidadeMedida', 'operador', 'quantidade', 'valor'], 
				'lista': null
			})
		},
		verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalComprovantes").modal('hide')
			$("#modalVerComprovante").modal('show')
		},
		buscarProdutos : function () {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}
			
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			if (this.pesquisa.loja == null) this.pesquisa.loja = 0;

			this.$axios({
				method: 'post',
				url: this.urlRest +'resultados/searchEntradasSaidasProdutos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.pesquisa.subcategoria = null
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data

				if (data.length > 0) {
					this.pesquisa.subcategoria = data[0]
				}
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		onSearchOperadores : function (search, loading) {
			if (search.length >= 3) {
				loading(true);
				this.buscarOperadores(loading, search, this);
			}
		},
		buscarOperadores : debounce((loading, search, vm) => {
			vm.$axios({
				method: 'post',
				url: vm.urlRest +'configuracoes/searchUsuarios',
				headers: {'Content-Type': 'application/json'},
				data: {'nome': search}
				
			}).then(response => {
				vm.listaOperadores = response.data.resultado

			}).finally(() => {
				loading(false)
			});
		}, 1000),
		buscarComprovantes : function (objProduto, isExcel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'idProduto': objProduto != null ? objProduto.idProduto : this.modal.idProduto, 
				'nomeProduto': objProduto != null ? objProduto.nomeProduto : this.modal.nomeProduto, 
				'operador': this.pesquisa.retorno.operador != null ? (objProduto != null ? objProduto.operador : this.modal.operador) : null, 
				'isEntrada': this.pesquisa.isEntrada, 
				'loja': this.pesquisa.retorno.loja, 
				'data': this.pesquisa.retorno.data, 
				'pagina': isExcel ? -1 : (objProduto != null ? 0 : this.modal.pagina),
				'resultado': objProduto != null ? [] : this.modal.resultado
			}

			$('#modalComprovantes').modal('show')
			Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

         this.$axios({
            method: 'post',
            url: this.urlRest +'resultados/searchComprovantesProduto',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

         }).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.modal.pagina++;
               this.modal.resultado.push(... response.data)
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

            if (isExcel) {
					this.$store.dispatch('exportarExcel', {
						'nome': 'Comprovantes',
						'resultado': this.listaExportacao, 
						'campos': ['id', 'motivo', 'nomeLoja', 'data', 'valor'], 
						'lista': null
					})
				}
         });
      }
	},
   mounted() {
      this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
      Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
   }
}

</script>

<style scoped>

.dropdown.dropstart {
	line-height: 0.8;
}

.form-check-label {
	font-weight: 400 !important;
   text-transform: none !important;
}

</style>